.button-link {
  background-color: transparent;
  border: none;
  color: rgb(7, 139, 248);
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
}

.button-link:hover,
.button-link:focus {
  text-decoration: none;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* #overlayImageContainer{
  position:relative;
} */

.overlayImagehide{
  position:absolute;
  top:0;
  left:0;
}

/* .overlayImagehide:hover{
  opacity:0;
} */

.iframeNoBorder{
  border:none
}


#threeDContainer {
  background: linear-gradient(90deg, #ffffff 60px, transparent 1%) center, linear-gradient(#ffffff 60px, transparent 1%) center, #505050;
  background-size: 62px 62px;
}
